<template>
    <div>
        <div class="text-h4 mb-3">THEME</div>
        <div>
            <v-radio-group class="mt-0"  v-model="uiSettings.value.darkMode" mandatory >
          <v-radio :value="false" >
            <template v-slot:label>
              <div >Light</div>
            </template>
          </v-radio>
          <v-radio :value="true">
            <template v-slot:label>
              <div >Dark</div>
            </template>
          </v-radio>
        </v-radio-group>

        <CBtn color="primary" class="px-8 mt-5 mb-2" prepen @click="changeMode()">
          Apply
        </CBtn>
        </div>
    </div>
</template>
<script>
import CBtn from '@/components/Vuetify/CBtn.vue'

import {getUserSetting, updateUserSetting} from '@/api/user/userSetting.js'
export default {
    name:'Settings',
    components:{CBtn},
    data:()=>({
        uiSettings: {
            key : 'uiSetting',
            value : {
                darkMode:false
            },
            id: null
        }
    }),
    created(){
        const _this = this
        getUserSetting( (res) =>{ 
            _this.uiSettings = res.find(item => item.key === 'uiSetting') 
        } )
    },
    methods:{
        changeMode(){
            const payload = this.uiSettings 
            const _this = this
            updateUserSetting(this.uiSettings.id, payload, ()=>{ 
                _this.$store.commit('darkModeState', _this.uiSettings.value.darkMode) 
             } , 'Theme mode saved successfully')
        }
    }
}
</script>
<style scoped>


</style>